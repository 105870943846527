import classes from './Terms.module.scss';
const Terms = () => {
    return(
        <div className={['page-wrapper', classes.Terms].join(' ')}>
            <h1>Terms of Service</h1>

        </div>
    );
};

export default Terms;