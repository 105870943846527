import React              from 'react';
import ReactDOM           from 'react-dom';
import App                from './App';
import reportWebVitals    from './reportWebVitals';
import { BrowserRouter }  from 'react-router-dom';
import { Provider }       from 'react-redux';
import store              from './store/store';
import './index.scss';

//const app = <React.StrictMode><App /></React.StrictMode>
const app = (
      <Provider store={store}>
            <BrowserRouter>
                  <App />
            </BrowserRouter>
      </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
